import "./Footer.css";


function Footer(params) {
    return(
        <>
       <div className="footer">
<p>&copy; 2022 Designed and Developed by Sara Alshater</p>
       </div>
      
        </>
    )
}


export default Footer;