import "./Spinner.css";




function LoadingSpinner(params) {
    return (
    <div className="loader"></div>
    );
}

export default LoadingSpinner;