import { Link } from "react-router-dom";
import "./ContactbtnMobile.css"

function ContactbtnMobile(){

return (
    <>
    <Link to="/contact" className="contactIconMobile">
     
        </Link>
    </>
)

}
 export default ContactbtnMobile;